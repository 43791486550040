import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TipoPais } from '../_utils/_tipos/pais';
import { GenericService } from 'src/app/_services/generic.service';
import { PersonaTipoBloqueo } from 'src/app/bloqueos/_models/persona-tipo-bloqueo';

@Injectable({
  providedIn: 'root'
})
export class BloqueosService extends GenericService<TipoPais>
{

  constructor(http: HttpClient)
  {
    super(http, "", "v1");
  }

  getBloqueos(): Observable<PersonaTipoBloqueo[]>
  {
    return this._http.get<PersonaTipoBloqueo[]>(`${this._apiCoreUrl}v1/PersonasTipoBloqueos`);
  }

}